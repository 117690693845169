<template>
  <div id="home">
    <div class="content_box">
      <div class="inner_max">
        <div class="link_parent_box">
          <slot name="title"></slot>
          <div class="link_box" v-if="showLink || idPass">
            <ul>
              <li class="active">
                <a @click="getDepositBlacklist()" class="el-button" data-testid="depositFunds">
                  {{ $t('common.button.depositFunds') }}
                </a>
                <em class="icon el-icon-arrow-right"></em>
              </li>
              <li>
                <a @click="getWithdrawalBlacklist()" class="el-button" data-testid="withdrawFunds">
                  {{ $t('common.button.withdrawFunds') }}
                </a>
                <em class="icon el-icon-arrow-right"></em>
              </li>
              <li v-if="openAdditionAccountEnabled">
                <a @click="$router.push('openLiveAccount')" class="el-button" data-testid="openLiveAccount">
                  {{ $t('menu.openAdditAcc') }}
                </a>
                <em class="icon el-icon-arrow-right"></em>
              </li>
              <li>
                <div>
                  <a @click.stop.prevent="$router.push('downloads')" class="el-button" data-testid="downloads">
                    {{ $t('common.button.download') }}
                  </a>
                  <em class="icon el-icon-arrow-right"></em>
                </div>
              </li>
            </ul>
          </div>
          <slot name="alerts"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
    <slot name="components"></slot>
  </div>
</template>

<script>
import { apiID3Status } from '@/resource/register';
import openAccountMixin from '@/mixins/openAccount';
import blackList from '@/mixins/blackList';

export default {
  name: 'HomeTemplate',
  data() {
    return {};
  },
  mixins: [openAccountMixin, blackList],
  props: {
    showLink: { type: Boolean, default: true }
  },
  mounted() {
    if (!this.idPass) this.checkIdPass();
  },
  methods: {
    checkIdPass() {
      apiID3Status().then(resp => {
        if (resp.data.data) this.$store.commit('common/setIdPass', true);
      });
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
