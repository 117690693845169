<template>
  <div class="change_leverage_dialog">
    <v-dialog :visible.sync="visible" :visibleFooter="true">
      <el-form
        :show-message="false"
        label-position="top"
        :rules="leverageUpdateFormRules"
        :model="leverageUpdateForm"
        ref="leverageUpdateForm"
      >
        <el-form-item :label="$t('common.field.accNum')">
          <span class="highlight-data" v-html="$parent.$parent.leveragePopupAccountNumber"></span>
        </el-form-item>
        <el-form-item :label="$t('common.field.currLeverage')">
          <span class="highlight-data">{{ $parent.$parent.leveragePopupLeverage }} : 1</span>
        </el-form-item>
        <el-form-item :label="$t('common.field.accEquity')">
          <span class="highlight-data">{{ $parent.$parent.leveragePopupEquity | formatNumber }}</span>
        </el-form-item>
        <el-form-item :label="$t('common.field.newLeverage')" prop="newLeverage">
          <el-select v-model="leverageUpdateForm.newLeverage" data-testid="newLeverage">
            <el-option
              name="newLeverage"
              v-for="item in $parent.$parent.leveragePopupAvaliableLeverages"
              :key="item.leverage"
              :label="`${item.leverage} : 1`"
              :value="item.leverage"
              :data-testid="item.leverage"
              @click.native="thousandLeverageValidation(item)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="info" v-if="showTNC">
          <div v-html="$t('home.leverage.tncTitle')" />
          <div v-html="$t('home.leverage.oneThousandRatio')" class="onethousand" v-if="showThousandLeverage" />
          <div v-html="$t('home.leverage.tnc')" />
        </el-form-item>
        <el-form-item prop="tnc" v-if="showTNC">
          <el-checkbox
            :label="$t('home.leverage.tncConfirm')"
            name="tnc"
            v-model="leverageUpdateForm.tnc"
            data-testid="tncConfirm"
          ></el-checkbox>
        </el-form-item>
        <div class="btn_box">
          <el-button @click="submitForm" data-testid="confirmChangeLeverage">
            {{ $t('common.button.changeLeverage') }}
          </el-button>
          <el-button plain @click="resetForm(false)" data-testid="cancelChangeLeverage">
            {{ $t('common.button.cancel') }}
          </el-button>
        </div>
      </el-form>
    </v-dialog>

    <v-dialog :visible.sync="checkVisible" :visibleFooter="true">
      <div class="dialog_body">
        <p class="mb-3">{{ $t('home.leverage.reduceLeverageWarn') }}</p>
        <p class="mb-3">{{ $t('home.leverage.reduceLeverageConfirm') }}</p>
        <el-button @click="updateDemoLeverage" data-testid="confirm">
          {{ $t('common.button.confirm') }}
        </el-button>
        <el-button plain @click="resetForm(false)" data-testid="cancel">
          {{ $t('common.button.cancel') }}
        </el-button>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import vDialog from './vDialog';
import { apiUpdate_leverage, apiUpdate_leverage_demo } from '@/resource';
import { checkAgreedTNC } from '@/util/validation';

export default {
  name: 'changeLeverage',
  props: {
    changeLeverageVisible: Boolean,
    leverageConfirmVisible: Boolean,
    leverageChangeSubmitSuccess: Boolean,
    leverageChangeSubmitFail: Boolean,
    leverageChangeSubmitReject: Boolean,
    leverageChangeAuditSuccess: Boolean,
    leverageChangeSubmitTimeOut: Boolean,
    showTNC: {
      type: Boolean,
      default: true
    },
    method: String
  },
  components: { vDialog },
  data() {
    const checkNewLeverage = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(''));
      }
      if (value == this.$parent.$parent.leveragePopupLeverage) {
        return callback(new Error(''));
      }
      return callback();
    };
    return {
      visible: false,
      checkVisible: false,
      thousandLeverage: false,
      leverageUpdateForm: {
        metaTraderLogin: '',
        currentLeverage: '',
        newLeverage: '',
        tnc: false,
        mt4_datasource_id: ''
      },
      leverageUpdateFormRules: {
        tnc: [{ validator: checkAgreedTNC, trigger: 'change' }],
        newLeverage: [{ validator: checkNewLeverage, trigger: 'change' }]
      }
    };
  },
  watch: {
    changeLeverageVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.resetForm(bool);
    }
  },
  methods: {
    thousandLeverageValidation(item) {
      let { failReason, leverage } = item;
      this.thousandLeverage = false;
      if (failReason && leverage === 1000) {
        this.thousandLeverage = true;
        this.failReasonNumber = failReason;
        this.errorMessage(this.$t(`oneThousandRatioError.error${failReason}`));
      }
    },
    submitForm() {
      if (this.thousandLeverage && this.leverageUpdateForm.tnc) {
        this.errorMessage(this.$t(`oneThousandRatioError.error${this.failReasonNumber}`));
        return;
      }

      this.$refs.leverageUpdateForm.validate(valid => {
        if (valid) {
          this.leverageUpdateForm.metaTraderLogin = this.$parent.$parent.leveragePopupAccountNumber;
          this.leverageUpdateForm.currentLeverage = this.$parent.$parent.leveragePopupLeverage;
          this.leverageUpdateForm.mt4_datasource_id = this.$parent.$parent.leverageMt4Datasource;
          if (this.method == 'live') {
            let msg = this.$parent.$parent.leveragePopupMsg;
            if (msg && this.showThousandLeverage) {
              this.errorMessage(this.$t(`oneThousandRatioError.error${msg}`));
              return;
            }
            this.updateLeverage();
          } else if (this.method == 'demo') {
            if (this.leverageUpdateForm.newLeverage < this.leverageUpdateForm.currentLeverage) this.checkVisible = true;
            else this.updateDemoLeverage();
          }
        } else {
          return false;
        }
      });
    },
    updateLeverage() {
      apiUpdate_leverage(this.leverageUpdateForm)
        .then(resp => {
          this.successMethod(resp);
        })
        .catch(err => {
          this.errorMethod(err);
        })
        .finally(() => {
          this.resetForm(false);
          this.$emit('update:leverageConfirmVisible', true);
        });
    },
    updateDemoLeverage() {
      apiUpdate_leverage_demo({
        currentLeverage: this.leverageUpdateForm.currentLeverage,
        metaTraderLogin: this.leverageUpdateForm.metaTraderLogin,
        newLeverage: this.leverageUpdateForm.newLeverage,
        mt4_datasource_id: this.leverageUpdateForm.mt4_datasource_id
      })
        .then(resp => {
          this.successMethod(resp);
        })
        .catch(err => {
          this.errorMethod(err);
        })
        .finally(() => {
          this.resetForm(false);
          this.$emit('update:leverageConfirmVisible', true);
        });
    },
    successMethod(resp) {
      console.log('resp:', resp);
      const returnCode = resp.data;
      if (returnCode == 0 || returnCode == 2) {
        this.$emit('update:leverageChangeSubmitSuccess', true);
        this.$emit('update:leverageChangeAuditSuccess', resp.data == 0);
      } else if (returnCode == 3) {
        this.$emit('update:leverageChangeSubmitReject', true);
      } else if (returnCode == 1) {
        this.$emit('update:leverageChangeSubmitFail', true);
      }
      this.$emit('resetTable');
    },
    errorMethod(err) {
      console.log('err', err);
      if (err.message.includes('timeout')) {
        this.$emit('update:leverageChangeSubmitTimeOut', true);
      } else {
        this.$emit('update:leverageChangeSubmitFail', true);
      }
    },
    resetForm(bool) {
      this.$nextTick(() => {
        this.checkVisible = false;
        this.$emit('update:changeLeverageVisible', bool);
        this.$refs.leverageUpdateForm.resetFields();
      });
    },
    errorMessage(message) {
      return this.$message({
        message: message,
        type: 'error'
      });
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    showThousandLeverage() {
      return this.leverageUpdateForm.newLeverage === 1000 ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/changeLeverage.scss';
</style>
