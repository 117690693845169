<template>
  <div class="change_password_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <div class="top">
        <p class="mb-2">{{ $t('common.field.accNum') }}</p>
        <h4 class="text-primary">{{ number }}</h4>
      </div>
      <div class="form_box">
        <el-form
          label-position="top"
          :model="changeFormLabelAlign"
          :rules="changingPasswordRules"
          ref="changeFormLabelAlign"
        >
          <el-form-item :label="$t('common.field.currPw')" prop="currentPassword" required class="currentPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.currentPassword"
              autocomplete="false"
              data-testid="currentPassword"
            ></el-input>
          </el-form-item>
          <a class="forgotPwd" @click="forgotPassword" data-testid="forgotPw">{{ $t('common.button.forgotPw') }}</a>
          <el-form-item class="pb-2" type="password" :label="$t('common.field.newPw')" required prop="newPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.newPassword"
              autocomplete="false"
              data-testid="newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.field.confirmPw')" required prop="confirmNewPassword">
            <el-input
              type="password"
              v-model="changeFormLabelAlign.confirmNewPassword"
              autocomplete="false"
              data-testid="confirmNewPassword"
            ></el-input>
          </el-form-item>
          <div class="btn_box">
            <el-button @click="changeSubmitForm" data-testid="changePw">
              {{ $t('common.button.changePw') }}
            </el-button>
            <el-button plain @click="disableChangePasswordVisible(false)" data-testid="cancel">
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </vDialog>
  </div>
</template>

<script>
import vDialog from './vDialog';
import { apiUpdate_mt4_account_password, apiCheck_current_password, apiCheck_new_password } from '@/resource';
import { rsa } from '@/util/encrypt';

export default {
  name: 'changePassword',
  props: {
    changePasswordVisible: Boolean,
    pwdConfirmVisible: Boolean,
    number: Number,
    isDemo: Boolean
  },
  components: { vDialog },
  data() {
    const validateCurrentPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.currPwReq')));
      } else {
        this.checkPasswordExisting().then(result => {
          if (!result) {
            callback(new Error(this.$t('common.formValidation.currPwWrong')));
          } else {
            this.changeFormLabelAlign.currentPasswordVerification = true;
          }
        });
      }
    };
    const validateDuplicatedPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.newPasswordVerification = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,16}$/;
        if (!reg.test(value)) {
          console.log(value);
          callback(new Error(this.$t('common.formValidation.newPwAccFormat')));
        } else {
          this.checkPasswordDuplication().then(result => {
            if (!result) {
              callback(new Error(this.$t('common.formValidation.IdenficalPw')));
            } else {
              this.changeFormLabelAlign.newPasswordVerification = true;
              callback();
            }
          });
        }
      }
    };
    const validateConfirmNewPassword = (rule, value, callback) => {
      this.changeFormLabelAlign.confirmNewPasswordVerfication = false;
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.confirmPwReq')));
      } else if (value !== this.changeFormLabelAlign.newPassword) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      } else {
        callback();
        this.changeFormLabelAlign.confirmNewPasswordVerfication = true;
      }
    };
    return {
      visible: false,
      changeFormLabelAlign: {
        userId: '',
        mtAccount: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        currentPasswordVerification: false,
        newPasswordVerification: false,
        confirmNewPasswordVerfication: false
      },
      changingPasswordRules: {
        currentPassword: [{ validator: validateCurrentPassword, trigger: 'blur' }],
        newPassword: [{ validator: validateDuplicatedPassword, trigger: 'blur' }],
        confirmNewPassword: [{ validator: validateConfirmNewPassword, trigger: 'blur' }]
      }
    };
  },
  watch: {
    changePasswordVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.disableChangePasswordVisible(bool);
    }
  },
  methods: {
    changeSubmitForm() {
      if (
        this.changeFormLabelAlign.currentPasswordVerification &&
        this.changeFormLabelAlign.newPasswordVerification &&
        this.changeFormLabelAlign.confirmNewPasswordVerfication
      ) {
        apiUpdate_mt4_account_password({
          userId: this.uuid,
          mtAccount: this.number,
          currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
          newPassword: rsa(this.changeFormLabelAlign.newPassword),
          isDemo: this.isDemo
        })
          .then(resp => {
            if (resp.data.code == 0) {
              this.$emit('update:changePasswordVisible', false);
              this.$emit('update:pwdConfirmVisible', true);
            }
          })
          .catch(err => {
            console.log(err);
            this.disableChangePasswordVisible(false);
          });
      }
    },
    disableChangePasswordVisible(bool) {
      this.$nextTick(() => {
        this.changeResetForm();
        this.$emit('update:changePasswordVisible', bool);
      });
    },
    changeResetForm() {
      this.$refs.changeFormLabelAlign.resetFields();
      this.$refs.changeFormLabelAlign.confirmNewPasswordVerfication = false;
      this.$refs.changeFormLabelAlign.currentPasswordVerification = false;
      this.$refs.changeFormLabelAlign.checkPasswordDuplication = false;
    },
    forgotPassword() {
      this.$emit('forgotPwdClick');
    },
    checkPasswordExisting() {
      return apiCheck_current_password({
        userId: this.uuid,
        mtAccount: this.number,
        currentPassword: rsa(this.changeFormLabelAlign.currentPassword),
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('the password exists');
            return Promise.resolve(true);
          } else {
            console.log("the password doesn't exist");
          }
        } else {
          console.log('Errors happened when verifing current password');
        }
        return Promise.resolve(false);
      });
    },
    checkPasswordDuplication() {
      return apiCheck_new_password({
        userId: this.uuid,
        mtAccount: this.number,
        newPassword: rsa(this.changeFormLabelAlign.newPassword),
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log("The new password doesn't exist");
            return Promise.resolve(true);
          } else {
            console.log('The new password duplicates');
          }
        } else {
          //wrong
          console.log('Errors happend when verifying new password');
        }
        return Promise.resolve(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/changePassword.scss';
</style>
